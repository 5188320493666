import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ControlAssociatedItemSummaryDTO, ControlFormDTO, ControlListResponseDTO } from '../dto/control.dto';
import { Observable } from 'rxjs';
import { MainBaseService } from '../../../../core/services/main-base-service';
import { ComplianceAssociatedItemResponseDTO } from '../../../../shared/dto/associated-item.dto';

@Injectable({
  providedIn: 'root',
})
export class ControlService extends MainBaseService<
  ControlListResponseDTO,
  ControlFormDTO
> {
  protected apiUrl = environment.apiUrl + '/controls';

  constructor(http: HttpClient) {
    super(http);
  }

  getRiskHeatmap(controlId: number): any {
    const url = `${this.apiUrl}/${controlId}/risk-heatmap`;
    return this.http.get<any>(url);
  }

  getControlAssociatedItemSummary(
    controlId: number
  ): Observable<ControlAssociatedItemSummaryDTO> {
    return this.http.get<ControlAssociatedItemSummaryDTO>(
      `${this.apiUrl}/${controlId}/associated-item-summary`
    );
  }

  markAsApplicable(controlId: number, item: any): Observable<any> {
    const url = `${this.apiUrl}/${controlId}/mark-as-applicable`;
    return this.http.put<any>(url, item);
  }

  markAsNotApplicable(controlId: number, item: any): Observable<any> {
    const url = `${this.apiUrl}/${controlId}/mark-as-not-applicable`;
    return this.http.put<any>(url, item);
  }

}
